import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Event from '../views/Event.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import AdminPanel from '../views/AdminPanel.vue'
import AdminLogin from '../views/AdminLogin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/event',
    name: 'Event',
    component: Event,
  },
  {
    path: '/payment/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: AdminLogin
  },
  {
    path: '/admin/panel',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      requiresAdminAccess: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAdminAccess)) {
      if (!localStorage.accessToken || localStorage.accessToken == "") {
          console.log("redirecting....");
          next({
              path: '/admin/login',
              params: { nextUrl: to.fullPath }
          });
      } else {
          next();
      }
  } if(to.matched.some(record => record.meta.requiresTicket)) {
    if (!localStorage.eventToken) {
        console.log("redirecting....");
        next({
            path: '/',
            params: { nextUrl: to.fullPath }
        });
    } else {
        next();
    }
} else {
    next();
  }
})
export default router
