<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-overlay :value="globalNotification" >
        <v-alert
            :type="success ? 'success' : 'error'"
            elevation="8"
            transition="fade-transition"
            >{{globalNotification}}
        </v-alert>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
  methods:{
    loadCache(){
      console.log("cache");
      if(localStorage.accessToken) this.$store.commit('SET_ACCESS_TOKEN', localStorage.accessToken);
    }
  },
  created(){
    this.loadCache();
  },
  computed: {
    ...mapState(['globalNotification','success']),
  },
};
</script>
