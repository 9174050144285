<template>
  <v-container fluid class="home justify-center">
    <v-row align="center" justify="center" style="height:100%;">
      <v-card class="col-md-8 ma-2">
        <v-tabs-items v-model="tab">
          <!-- Home tab -->
          <v-tab-item class="custom-tab-item mx-1">
            <div class="d-flex flex-column" style="flex: 1 0 auto;">
              <div class="header d-flex align-center justify-center">
                <img alt="Vue logo" src="../assets/logo.png">
              </div>
              <v-spacer/>
              <v-row>
                <v-col cols="6" class="d-flex">
                  <v-form class="flex-grow-1 d-flex flex-column justify-space-between" v-model="validGoToEvent">
                    <h2 class="text-center mb-6">Vaata ülekannet</h2>
                    <v-text-field class="flex-grow-0" solo placeholder="Näide - AAAFFSSCCCC22X" v-model="usedCode">
                      <template v-slot:append>
                        <v-icon v-if="codeCheck == true" color="green">
                          mdi-check-circle
                        </v-icon>
                        <v-icon v-else-if="codeCheck == false" color="yellow darken-1">
                          mdi-alert-circle
                        </v-icon>
                      </template>
                    </v-text-field>
                    <v-btn :loading="loading" :disabled="!codeCheck" class="flex-grow-0 mb-1" color="green" @click="watchEvent()">Vaata</v-btn>
                  </v-form>
                </v-col>
                <v-divider vertical/>
                <v-col cols="6" class="d-flex">
                  <v-form class="flex-grow-1 d-flex flex-column justify-space-between" v-model="validSelectedEvent">
                    <h2 class="text-center mb-6">Osta pilet</h2>
                    <v-select :error-messages="selectErrorMsgs" class="flex-grow-0" solo placeholder="Vali üritus" v-model="selectedEventId" :items="selectableEvents" item-text="displayName" item-value="id"/>
                    <v-btn class="flex-grow-0 mb-1" color="red darken-2" @click="selectEvent">Osta</v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <!-- Buy Ticket tab -->
          <v-tab-item class="custom-tab-item mx-1" v-if="selectedEvent != null">
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-form ref="ticketForm" v-model="validBuyTicket" lazy-validation class="d-flex flex-column justify-space-between flex-grow-1">
                  <div class="d-flex justify-space-between">
                    <v-btn class="flex-grow-0" icon @click="tab=0">
                      <v-icon>
                        mdi-arrow-left
                      </v-icon>
                    </v-btn>
                    <h2 class="text-center mb-2">Osta pilet</h2>
                    <div style="width:24px;height:24px;"/>
                  </div>
                  <v-container class="mx-0 px-0">
                    <h2 class="mb-2">{{selectedEvent.name}}</h2>
                    <v-row class="py-1">
                      <v-col cols="3" class="py-0">
                        <span>Aeg</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <span>{{selectedEvent.event_start_time}} - {{selectedEvent.event_end_time}}</span>
                      </v-col>
                    </v-row>
                    <v-row class="py-1">
                      <v-col cols="3" class="py-0">
                        <span>Hind</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <span>{{financial(selectedEvent.price)}}€</span>
                      </v-col>
                    </v-row>
                    <v-row class="pt-1 pb-3">
                      <v-col cols="3" class="py-0">
                        <span>Kogus</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <span>1</span>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div>
                    <v-text-field class="flex-grow-0" solo placeholder="Nimi" v-model="clientName" :rules="rules.name" aria-required/>
                    <v-text-field type="email" class="flex-grow-0" solo placeholder="E-mail" v-model="clientEmail"  :rules="rules.email" aria-required/>
                    <v-text-field class="flex-grow-0" solo placeholder="Ettevõtte nimi (valikuline)" v-model="clientCompany"/>
                  </div>
                  <v-row class="flex-grow-0">
                    <v-col cols="3" class="py-2 d-flex align-center">
                      <span class="font-weight-bold">Makseviis</span>
                    </v-col>
                    <v-col cols="9" class="py-2">
                      <span class="font-weight-bold">Maksmiseks suunatakse Teid Maksekeskus AS keskkonda, kus saate valida erinevate Eesti pankade ja muude makseviiside vahel.</span>
                      <!-- <v-btn class="mx-2">SEB</v-btn>
                      <v-btn class="mx-2">LHV</v-btn>
                      <v-btn class="mx-2">Swed</v-btn>
                      <v-btn class="mx-2">Luminor</v-btn> -->
                    </v-col>
                  </v-row>
                  <v-divider class="my-2"/>
                  <v-row class="flex-grow-0">
                    <v-col cols="3" class="py-2">
                      <span class="font-weight-bold">Kokku (koos KM)</span>
                    </v-col>
                    <v-col cols="9" class="py-2 d-flex align-center">
                      <span>{{financial(selectedEvent.price)}}€</span>
                    </v-col>
                  </v-row>
                  <v-checkbox :rules="rules.checkbox" hide-details>
                    <template v-slot:label>
                      <div>
                        Olen lugenud ja nõustun veebilehe
                            <a
                              target="_blank"
                              href="https://www.eventmedia.ee/muugitingimused/"
                              @click.stop
                            >
                              Müügitingimustega
                            </a>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-btn :loading="loading" class="flex-grow-0 mb-1 mx-1" color="red darken-2" @click="checkout()">Mine maksma</v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <v-bottom-navigation
    fixed
    class="align-center"
    >
    <span>EventMedia OÜ</span>
    <v-divider class="mx-3" vertical/>
        <a
          target="_blank"
          href="https://www.eventmedia.ee/muugitingimused/"
          @click.stop
        >
          Müügitingimused
    </a>
    <v-divider class="mx-3" vertical/>
        <a
          target="_blank"
          href="https://www.eventmedia.ee/privaatsuspoliitika/"
          @click.stop
        >
          Privaatsustingimused
    </a>
    <v-divider class="mx-3" vertical/>
        <a
          target="_blank"
          href="https://www.eventmedia.ee/kontakt/"
          @click.stop
        >
          Kontakt
    </a>
    </v-bottom-navigation>
    <v-overlay :value="overlay" >
        <v-alert
        v-if="notification"
        :type="notification.success ? 'success' : 'error'"
        elevation="8"
        transition="fade-transition"
        >{{notification ? notification.message: ''}}
        </v-alert>
    </v-overlay>
  </v-container>
<!-- Mobiilivaade -->
  <!-- <div class="home d-flex flex-column">
    <div class="header d-flex align-center justify-center">
      <img alt="Vue logo" src="../assets/logo.png">
    </div>
    <v-btn color="ma-3 green text-h3">Vaata</v-btn>
    <v-btn color="ma-3 blue text-h3">Osta</v-btn>
    <div class="ma-3 d-flex flex-column">
      <a href="#" class="text-center">Müügitingimused</a>
      <a href="#" class="text-center">Privaatsustingimused</a>
    </div>
  </div> -->
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
  name: 'Home',
  components: {
  },
  data(){
    return{
      valid: null,
      // selectedEventId: null,
      selectedEventId: "fbba91b3-6727-4f43-a9ab-5601d372f9d6",
      tab: 0,
      selectErrorMsgs: [],
      usedCode: "",
      clientName: "Oliver",
      clientEmail: "",
      clientCompany: "",
      boughtCode: "ABC123DEF356",
      conditionsRead: false,
      validGoToEvent: null,
      validSelectedEvent: null,
      validBuyTicket: null,
      rules:{
        name:[
          value => !!value || 'Nõutud.',
        ],
        email:[
          value => !!value || 'Nõutud.',
          value => {
              const pattern = new RegExp("^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$");
              return pattern.test(value) || 'Ebakorrektne aadress.'
          }
        ],
        checkbox:[
          value => !!value || 'Nõutud.',
        ],
      },
      loading: false,
      notification: null,
      overlay: false
    }
  },
  created(){
    this.loadingTicketEvents = true;
    this.getTicketEvents().then(() =>{

    });
  },
  methods: {
    ...mapActions(['getTicketEvents','buyTicket']),
    financial(x) {
      return Number.parseFloat(x).toFixed(2);
    },
    selectEvent(){
      this.selectErrorMsgs = []; 
      if(this.selectedEventId == '' || this.selectedEventId == null) this.selectErrorMsgs.push('Palun vali üritus!');
      if(this.selectErrorMsgs.length > 0) return;
      this.tab = 1;
    },
    checkout(){
      if(!this.$refs.ticketForm.validate()) return;
      this.loading = true;

      const details = {
        event_id: this.selectedEventId,
        paid_amount: this.selectedEvent.price,
        client_name: this.clientName,
        client_email: this.clientEmail,
        client_company_name: this.clientCompany
      }

      this.buyTicket(details)
      .then((transaction_id) =>{
        this.loading = false
        let redirectURL = "https://otse.eventmedia.ee";
        let notificationURL = "https://eventmedia.evenstad.ee";
        //let redirectURL = "https://oliver.evenstad.ee";
        let transProp = "?transaction_id=" + transaction_id;
        let url ="https://payment.test.maksekeskus.ee/pay/1/link.html?shop=30d7d5d1-ec42-49ab-aa11-458cda4be9c9&amount=" + 
        this.selectedEvent.price +
        "&reference=" + 
        "123abc" +
        "&country=ee&locale=et&return_url=" + redirectURL + "/payment/success" + transProp + "&return_method=GET&cancel_url=" + redirectURL + "/" + transProp + "&cancel_method=GET&notification_url=" + notificationURL + "/gateway/notification" + transProp + "&notification_method=GET";
        window.location = url;
      })
      .catch(() =>{
        this.loading = false
      })

      // //let redirectURL = "http://localhost:8080";
      // let redirectURL = "https://oliver.evenstad.ee";
      // let url ="https://payment.test.maksekeskus.ee/pay/1/link.html?shop=30d7d5d1-ec42-49ab-aa11-458cda4be9c9&amount=" + 
      // this.selectedEvent.price +
      // "&reference=" + 
      // "123abc" +
      // "&country=ee&locale=et&return_url=" + redirectURL + "/payment/success&return_method=GET&cancel_url=" + redirectURL + "/payment/success&cancel_method=GET&notification_url=" + redirectURL + "/notification&notification_method=GET";
      // window.location = url;
    },
    watchEvent(){
      this.$router.push('/event?code=' + this.usedCode);
      // this.loading = true;
      // setTimeout(() =>{
      //   this.loading = false;
      //   if(this.usedCode == "ABC123DEF456"){
      //     this.$router.push('/event')
      //   }
      //   else{
      //     this.notification = {
      //       success: false,
      //       message: 'Antud kood ei ole valiidne, palun proovi uuesti!'
      //     }
      //   }
      // }, 1000)
    }
  },
  computed: {
    ...mapState(['events']),
    selectableEvents: function(){
      const events = [];
      if(this.events === null) return;
      for(let i = 0; i < this.events.length; i++){
        let event = this.events[i];
        event.displayName = event.name + " - " + event.event_start_time + " - " + event.price + "€";
        events.push(event);
      }
      return events;
    },
    codeCheck: function(){
      if(this.usedCode.length == 0) return null;
      if(this.usedCode.length == 12) return true;
      return false;
    },
    selectedEvent: function(){
      if(this.selectedEventId == '' || this.selectedEventId == null) return null;
      if(this.events === null) return null;
      return this.selectableEvents.find(x => x.id == this.selectedEventId);
    }
  },
  watch: {
    notification: function(){
      if(this.notification == null) this.overlay = false;
      this.overlay = true;
      setTimeout(() => this.overlay = false, 2000);
    },
    selectableEvents: function(){
      if(this.selectableEvents.length > 0){
        this.selectedEventId = this.selectableEvents[0].id;
      }
    }
  }
  
}
</script>

<style scoped>
.home{
  height:100%;
}

button{
  flex-grow: 1;
}

.custom-tab-item {
  display: flex;
  flex-direction: column;
  min-height: 600px;
}
</style>