<template>
  <v-container>
    <v-app-bar flat height="75">
      <h1>EventMedia LIVE Admin</h1>
      <v-spacer />
      <v-btn icon small @click="logout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <h2 class="mt-2">Üritused</h2>
    <v-row v-if="loadingEvents" class="my-8">
        <v-col cols="12" class="d-flex justify-center">
            <v-progress-circular size="64" indeterminate/>
        </v-col>
    </v-row>
    <v-expansion-panels v-else multiple v-model="openPanels" class="mt-2 mb-5">
      <v-expansion-panel v-for="event in editableEvents" :key="event.id">
        <v-expansion-panel-header disable-icon-rotate>
          <v-row>
            <v-col cols="4">
              <strong>{{ event.name.toUpperCase() }}</strong>
            </v-col>
            <v-col cols="4"> Vaadatav: {{ !event.disabled }} </v-col>
            <v-col cols="4"> Müük avatud: {{ event.tickets_for_sale }} </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <h3>Pileti hind</h3>
            </v-col>
            <v-col cols="8"> {{ event.price }} € </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3>Ürituse algusaeg</h3>
            </v-col>
            <v-col cols="8">
              {{ event.event_start_time }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3>Ürituse lõpuaeg</h3>
            </v-col>
            <v-col cols="8">
              {{ event.event_end_time }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3>Ürituse viide arve</h3>
            </v-col>
            <v-col cols="8">
              {{ event.invoice_reference }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3>Kirjeldus</h3>
            </v-col>
            <v-col cols="8">
              {{ event.description }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <h3>Landing Page imporditud</h3>
            </v-col>
            <v-col cols="8">
              {{ event.full_page_embed_enabled }}
            </v-col>
          </v-row>
          <v-row v-if="event.full_page_embed_enabled">
            <v-col cols="4">
              <h3>Landing Page URL</h3>
            </v-col>
            <v-col cols="8">
              {{ event.description }}
            </v-col>
          </v-row>
          <template v-else>
            <v-divider class="my-8" />
            <h3>Lisatud osad</h3>
            <v-row v-for="part in event.embedded_parts" :key="part.id">
              <v-col cols="4">
                <h3>{{ part.is_html_content ? "[HTML]" : "[NORMAL]" }}</h3>
              </v-col>
              <v-col cols="8">
                {{ part.content }}
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn block color="green" @click="dialog=true"> Lisa üritus </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="800px">
    <v-card>
        <v-card-title>
        <span class="headline">Üritus</span>
        </v-card-title>
        <v-card-text>
        <v-container v-if="saving" class="d-flex justify-center align-center"><v-progress-circular indeterminate></v-progress-circular></v-container>
        <v-container v-else>
            <v-row>
                <v-col cols="12">
                    <v-text-field outlined label="Pealkiri" placeholder="Näide - Ooper Estonias"  v-model="newEvent.name"/>
                </v-col>
                <v-col cols="12">
                    <v-text-field outlined label="Algusaeg" placeholder="Näide - 03.12.2021 13:00" v-model="newEvent.event_start_time"/>
                </v-col>
                <v-col cols="12">
                    <v-text-field outlined label="Lõpuaeg" placeholder="Näide - 03.12.2021 15:00" v-model="newEvent.event_end_time"/>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field type="number" label="Piletihind" outlined v-model="newEvent.price"/>
                </v-col>
                <v-col cols="6" md="4">
                    <v-text-field  label="Viide arvel" outlined v-model="newEvent.invoice_reference"/>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox label="Müük avatud" v-model="newEvent.tickets_for_sale"/>
                </v-col>
                <v-col cols="12">
                    <v-textarea auto-grow label="Kirjeldus" outlined v-model="newEvent.description"/>
                </v-col>
            </v-row>
            <v-divider class="my-2"/>
            <v-row>
                <v-col cols="12">
                    <v-switch label="Süsteemiväline Landing Page" v-model="newEvent.full_page_embed_enabled"/>
                </v-col>
            </v-row>
            <v-row v-if="newEvent.full_page_embed_enabled">
                <v-col cols="12" md="6">
                    <v-text-field type="number" label="Landing Page URL" outlined v-model="newEvent.full_page_url"/>
                </v-col>
            </v-row>
            <template v-else>
                <v-row v-for="(part,index) in newEvent.embedded_parts" :key="part.id">
                    <v-col cols="3">
                        <v-switch label="HTML Sisu" v-model="part.is_html_content"/>
                        <v-btn color="red darken-1" @click="deleteEmbeddedPart(index)">Kustuta</v-btn>
                    </v-col>
                    <v-col cols="9">
                        <v-textarea auto-grow label="Sisu" outlined v-model="part.content"/>
                    </v-col>
                </v-row>
                <v-btn block @click="addEmbeddedPart()">Lisa sisublokk</v-btn>
            </template>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
            Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="saveEvent()">
            Save
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "AdminPanel",
  data() {
    return {
      openPanels: [],
      dialog: false,
      newEvent: {
        name: "",
        description: "",
        event_start_time: "",
        event_end_time: "",
        price: 0,
        invoice_reference: "",
        tickets_for_sale: true,
        disabled: false,
        full_page_url: "",
        full_page_embed_enabled: false,
        embedded_parts: [],
      },
      saving: false,
      loadingEvents: false
    };
  },
  methods: {
      ...mapActions({
          postEvent: 'saveEvent',
          getEvents: 'getEvents'
      }),
      addEmbeddedPart(){
          this.newEvent.embedded_parts.push({
              id: uuidv4(),
              is_html_content: true,
              content: ""
          })
      },
      deleteEmbeddedPart(index){
          this.newEvent.embedded_parts.splice(index,1);
      },
      saveEvent(){
          this.saving = true;
          this.postEvent(this.newEvent)
          .then(() =>{
              this.saving = false;
              this.dialog = false;
          })
          .catch(() =>{
              this.saving = false;
              this.dialog = false;
          })
      },
      logout(){
          this.$store.commit('SET_ACCESS_TOKEN','');
          this.$router.push('/admin/login');
      }
  },
  created(){
      this.loadingEvents = true;
      this.getEvents()
      .then(() =>{
          this.loadingEvents = false;
      })
      .catch(() =>{
          this.loadingEvents = false;
      })
  },
  computed: {
    ...mapState(["editableEvents"]),
  },
};
</script>

<style>
</style>