<template>
  <v-container v-if="loading || !event.full_page_embed_enabled" class="col-md-8" fluid>
    <template v-if="loading">
      <h1>Laen sisu</h1>
      <v-progress-circular indeterminate size="64"/>
    </template>
    <template v-else>
      <template>
        <v-row>
            <v-col cols="12" class="d-flex justify-space-between">
                <h1>{{event.name}}</h1>
                <v-btn x-large icon @click="$router.push('/')">
                    <v-icon>
                        mdi-logout
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-for="(part, index) in event.embedded_parts" :key="index">
            <v-col v-if="part.is_html_content" cols="12" class="d-flex" v-html="part.content"></v-col>
            <v-col v-else cols="12" class="d-flex">{{part.content}}</v-col>
        </v-row>
        <v-row >
            <v-col cols="12" class="d-flex">
                {{event.description}}
            </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
  <iframe v-else :src="event.full_page_url" style="width: 100%; height: 100%"/>
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
  name: 'Event',
  components: {
  },
  data(){
    return{
      loading: true,
      code: ''
    }
  },
  created(){
    if(!this.$route.query.code){
      this.$router.push('/');
    }
    this.code = this.$route.query.code;
    this.getEventForWatching(this.code)
    .then(() =>{
      this.loading = false;
    })
    .catch(() =>{
      this.$router.push('/');
    })

  },
  methods: {
    ...mapActions(['getEventForWatching'])
  },
  computed: {
    ...mapState(['event']),
  }
}
</script>

<style>
</style>