<template>
  <v-container fluid class="home justify-center">
    <v-row align="center" justify="center" style="height:100%;">
      <v-card class="col-md-8 ma-2">
        <v-tabs-items v-model="tab">
          <!-- Success tab -->
          <v-tab-item class="custom-tab-item mx-1">
            <v-row>
              <v-col cols="12" class="d-flex flex-column justify-space-between">
                <h1 class="text-center mb-2">Pileti ostmine õnnestus!</h1>
                <v-container class="mx-0 px-0">
                  <v-row>
                    <v-col cols="3" class="py-0 d-flex align-center">
                      <span class="font-weight-bold">Kood</span>
                    </v-col>
                    <v-col v-if="!waitingForCode" cols="9" class="py-0 d-flex align-center">
                      <v-text-field ref="codeField" class="flex-grow-1" solo placeholder="Kood" :value="boughtCode" hide-details readonly>
                      </v-text-field>
                      <v-btn class="flex-grow-0 ml-3" icon @click="copyCode">
                        <v-icon>
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-else cols="9" class="py-0 d-flex align-center justify-center">
                      <v-progress-circular indeterminate/>
                    </v-col>
                  </v-row>
                </v-container>
                <p>Saadame 5 minuti jooksul ostu kinnituse ka emailile, kus on ka sissepääsuks vajalik kood olemas. Nüüd võid kohe vaatama asuda või hiljem naasta ning saadud koodiga siseneda vaatamisplatvormile!</p>
                <p>PS! Mõnikord, kui koormus on suurem, võib pileti loomine võtab veidi kauem aega, vabandame selle eest!</p>
                <div class="d-flex flex-column">
                  <v-btn :disabled="boughtCode==''"  elevation="5" class="flex-grow-0 mb-4 mx-1" color="green" @click="$router.push('/event?code='+boughtCode)" block>Mine vaatama</v-btn>
                  <v-btn elevation="0" class="flex-grow-0 mb-1 mx-1 my" color="gray" @click="$router.push('/')" block>Tagasi algusesse</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
  </v-container>
<!-- Mobiilivaade -->
  <!-- <div class="home d-flex flex-column">
    <div class="header d-flex align-center justify-center">
      <img alt="Vue logo" src="../assets/logo.png">
    </div>
    <v-btn color="ma-3 green text-h3">Vaata</v-btn>
    <v-btn color="ma-3 blue text-h3">Osta</v-btn>
    <div class="ma-3 d-flex flex-column">
      <a href="#" class="text-center">Müügitingimused</a>
      <a href="#" class="text-center">Privaatsustingimused</a>
    </div>
  </div> -->
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
  name: 'PaymentSuccess',
  components: {
  },
  data(){
    return{
      boughtCode: "",
      tab: 0,
      waitingForCode: true,
      transaction_id: "",
      maxNoOfTries: 30,
      tried: 0
    }
  },
  created(){
      this.waitingForCode = true;
      if(!this.$route.query.transaction_id){
        this.$router.push('/');
        return;
      }
      this.transaction_id = this.$route.query.transaction_id;
      this.$router.replace('/payment/success')
      
      this.checkForCode();

      console.log(JSON.parse(this.$route.query.json));
  },
  methods: {
    ...mapActions(['getCodeFromTransaction']),
    copyCode(){
        this.$refs.codeField.$el.querySelector('input').select();
        this.$refs.codeField.$el.querySelector('input').setSelectionRange(0,99999);

        document.execCommand("copy");
    },
    checkForCode(){
      if(this.tried >= this.maxNoOfTries){
        this.waitingForCode = false;
        return;
      }
      this.tried += 1;
      this.getCodeFromTransaction(this.transaction_id)
      .then((code) =>{
        this.boughtCode = code;
        this.waitingForCode = false;
      })
      .catch(() =>{
          setTimeout(() => this.checkForCode(), 2000);
      });
    }
  },
  computed: {
    ...mapState(['events']),
    selectedEvent: function(){
      if(this.selectedEventId == '' || this.selectedEventId == null) return null;
      if(this.events === null) return null;
      return this.events.find(x => x.id == this.selectedEventId);
    },

  },
  
}
</script>

<style scoped>
.home{
  height:100%;
}

button{
  flex-grow: 1;
}

.custom-tab-item {
  display: flex;
  flex-direction: column;
  min-height: 600px;
}
</style>